import { useEffect, useState } from 'react'
import OfficialPartners from 'components/sections/OfficialPartners'
import FeaturedPropertiesSection from 'components/sections/FeaturedPropertiesSection'
import GuestReviewsSection from 'components/sections/GuestReviewsSection'
import DestinationsSection from 'components/sections/DestinationsSection'
import ServicesSection from 'components/sections/ServicesSection'
import BecomeAMemberSection from 'components/sections/BecomeAMemberSection'
import MagazineSection from 'components/sections/MagazineSection'
import welcomeBgImage from 'assets/images/welcome-bg.jpg'
import { config } from 'data/config'
import PublicLayout, { PublicLayoutProps } from 'layouts/PublicLayout'
import { MobilePublicNavbarProps } from 'components/mobile/MobilePublicNavbar'
import Testimonials from 'components/sections/Testimonials'
import HomepageSearchBar from 'components/HomepageSearchBar'
import { FormProvider, useForm } from 'react-hook-form'
import PropertySearchModal from 'components/modals/home/search/PropertySearchModal'
import { useSearchParams } from 'data/hooks/useSearchParams'
import { DestinationSearchFormFields } from 'components/home/navbar/destination/DestinationNavbar'
import { getNumericQueryParameter, parseDate } from '@ama-selections/ui'
import { getNumericArrayQueryParameter, getStringArrayQueryParameter } from '@ama-selections/ui/src/helpers/destination'
import { useDispatch } from 'react-redux'
import { setSearchingDestinations } from 'store/searchbar'
import { useHasScrolled } from 'data/hooks/useHasScrolled'

const HomePage = () => {
  const form = useForm<DestinationSearchFormFields>()
  const dispatch = useDispatch()
  const { params } = useSearchParams()
  const [isSearchOpen, setIsSearchOpen] = useState(false)
  const hasScrolled = useHasScrolled(0, true)

  // if the user has search params in the URL, set the form values
  useEffect(() => {
    form.reset({
      adults: form.getValues('adults') ?? getNumericQueryParameter(params.get('adults')) ?? undefined,
      children: form.getValues('children') ?? getNumericQueryParameter(params.get('children')) ?? undefined,
      dates: {
        startDate: form.watch('dates')?.startDate ?? (params.get('arrival') ? parseDate(params.get('arrival'))?.toDate() : undefined),
        endDate: form.watch('dates')?.endDate ?? (params.get('departure') ? parseDate(params.get('departure'))?.toDate() : undefined),
      },
      filters: {
        bedrooms: form.getValues('filters.bedrooms') ?? getNumericQueryParameter(params.get('bedrooms')),
        bathrooms: form.getValues('filters.bathrooms') ?? getNumericQueryParameter(params.get('bathrooms')),
        minimumPrice: form.getValues('filters.minimumPrice') ?? getNumericQueryParameter(params.get('minimumPrice')),
        maximumPrice: form.getValues('filters.maximumPrice') ?? getNumericQueryParameter(params.get('maximumPrice')),
        propertyTypes: form.getValues('filters.propertyTypes') ?? getNumericArrayQueryParameter(params.get('propertyTypes')),
        searchCategories: form.getValues('filters.searchCategories') ?? getStringArrayQueryParameter(params.get('searchCategories')),
      },
      destination_search: form.getValues('destination.value') ?? params.get('destination'),
    })
  }, [])

  useEffect(() => {
    dispatch(setSearchingDestinations(false))
  }, [])

  const seo: PublicLayoutProps['seo'] = {
    title: 'Luxury Villa Rentals by A.M.A Selections | Luxury Villas Europe - Caribbean Villa Rental',
    description: 'Exclusive luxury holiday villa rentals in Europe, Morocco and the Caribbean with A.M.A Selections. Browse the world’s best luxury vacation homes.',
    openGraph: {
      type: 'website',
      url: config.appURL,
      images: [
        {
          url: welcomeBgImage.src,
          width: 1922,
          height: 760,
        },
      ],
    },
  }

  const mobileNavbar: MobilePublicNavbarProps = {
    useVideo: true,
    visible: hasScrolled,
    profileTrigger: true,
    back: true,
    images: [{
      url: welcomeBgImage.src,
      alt_text: 'Luxury Villa Rentals in France, Italy, Spain & Greece',
    }],
  }

  const featuredImage: PublicLayoutProps['featuredImage'] = {
    image: welcomeBgImage.src,
    title: 'A definitive collection of the world’s most exclusive holiday homes',
    useVideo: true,
    darken: true,
    mobile: {
      showTitle: true,
      position: 'content',
    },
    search: {
      element: <HomepageSearchBar form={form} />,
    },
    size: 'lg',
  }

  const actionFooter: PublicLayoutProps['actionFooter'] = {
    scrollDelay: 800,
    button: {
      children: 'Edit Search',
      onClick: () => setIsSearchOpen(true),
    },
  }

  return (
    <>
      <FormProvider {...form}>
        <PublicLayout
          seo={seo}
          featuredImage={featuredImage}
          mobileNavBar={mobileNavbar}
          actionFooter={actionFooter}
        >
          <OfficialPartners />
          <Testimonials />
          <FeaturedPropertiesSection />
          <GuestReviewsSection />
          <DestinationsSection />
          <ServicesSection />
          <BecomeAMemberSection />
          <MagazineSection />
        </PublicLayout>

        <PropertySearchModal
          isOpen={isSearchOpen}
          onClose={() => setIsSearchOpen(false)}
        />
      </FormProvider>
    </>
  )
}

export default HomePage
