import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import placeholderImage from 'assets/images/placeholder.png'
import { Swiper, SwiperClass, SwiperProps, SwiperSlide } from 'swiper/react'
import { Pagination, Navigation, FreeMode } from 'swiper/modules'
import Image from 'components/Image'
import { FeaturedRegionsQuery } from 'gql/graphql'
import { getLoadedImagesArray, getNumberArray } from 'data/helpers/carousel'
import { useScreenWidth } from 'data/hooks/useScreenWidth'
import { useHasLoaded } from 'data/hooks/useHasLoaded'
import Headers from 'components/home/common/typography/Headers'
import Paragraphs from 'components/home/common/typography/Paragraphs'
import Link from 'components/Link'

interface CarouselDestinationsProps {
  destinations: FeaturedRegionsQuery['cmsPopularDestinations']['data']
}

const CarouselDestinations = ({
  destinations,
}: CarouselDestinationsProps) => {
  const { ref: componentRef, hasLoaded } = useHasLoaded()
  const [swiperProps, setSwiperProps] = useState<SwiperProps | null>(null)

  const pagination = {
    type: 'bullets' as const,
    clickable: true,
    clickableClass: 'carousel-clickable-destinations',
    bulletClass: 'carousel-bullet ',
  }

  const breakpoints = {
    768: {
      spaceBetween: 30,
    },
  }

  const imagesInFront = 3
  const imagesBehind = 2
  const imagesLength = destinations.length
  const numberArray = getNumberArray(imagesLength)
  const [loadedImages, setLoadedImages] = useState([...numberArray.slice(0, imagesInFront), ...numberArray.slice(-imagesBehind)])

  const { isMobile } = useScreenWidth(768)

  useEffect(() => {
    const similarProps: SwiperProps = {
      className: 'relative pb-50',
      slidesPerView: 'auto',
      observeParents: true,
      observer: true,
      centeredSlides: true,
      loop: true,
      pagination: pagination,
      freeMode: {
        enabled: true,
        sticky: true,
        momentum: false,
      },
    }

    if (!isMobile) {
      setSwiperProps({
        ...similarProps,
        modules: [Pagination, Navigation, FreeMode],
        spaceBetween: 10,
        navigation: true,
        onRealIndexChange: (swiper: SwiperClass) => {
          if (loadedImages.length !== imagesLength) {
            setLoadedImages(getLoadedImagesArray(
              swiper.realIndex,
              imagesInFront,
              imagesLength,
              numberArray,
              loadedImages,
            ))
          }
        },
      })
    } else {
      setSwiperProps({
        ...similarProps,
        modules: [Pagination, FreeMode],
        spaceBetween: 20,
        breakpoints: breakpoints,
        onRealIndexChange: (swiper: SwiperClass) => {
          if (loadedImages.length !== imagesLength) {
            setLoadedImages(getLoadedImagesArray(
              swiper.realIndex,
              imagesInFront,
              imagesLength,
              numberArray,
              loadedImages,
            ))
          }
        },
      })
    }
  }, [isMobile])

  return (
    <div ref={componentRef}>
      {(hasLoaded && (destinations ?? []).length > 0) && (
        <Swiper {...swiperProps}>
          {
            destinations.map((destination, index) => (
              <SwiperSlide
                className="w-[265px] sm:w-[395px] md:w-full lg:w-[1000px] relative max-h-[600px] md:max-h-[700px] mt-50 lg:mt-[62px]"
                key={index}
              >
                <Link href={`/${destination.region.country.slug}/${destination.region.slug}`}>
                  <a className="w-[265px] sm:w-[395px] md:w-full h-full">
                    <div className="items-center justify-center h-[435px] sm:h-[650px] relative">
                      {loadedImages.includes(index) && (
                        <Image
                          src={destination.region.main_media?.url ?? placeholderImage}
                          alt={destination.region.main_media?.file_name}
                          className="absolute inset-0 object-cover rounded-4 md:rounded-none"
                          layout="fill"
                          priority
                        />
                      )}
                      <div className="absolute bottom-[20%] inset-x-0 flex flex-col items-center justify-center md:inset-0 px-20 md:px-120">
                        <Headers.CarouselTitle className="text-white mb-30">
                          {destination.region.value}, {isMobile && <br />} {destination.region.country.value}
                        </Headers.CarouselTitle>

                        <Paragraphs.XL className="hidden text-white opacity-90 !leading-24 lg:block max-w-[750px]">
                          {destination.description}
                        </Paragraphs.XL>
                      </div>
                    </div>
                  </a>
                </Link>
              </SwiperSlide>
            ))}
        </Swiper>
      )}
    </div>

  )
}

CarouselDestinations.propTypes = {
  destinations: PropTypes.array,
}

export default CarouselDestinations
