import React, { useState } from 'react'
import PropTypes from 'prop-types'
import placeholderImage from 'assets/images/placeholder.png'
import Link from 'components/Link'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination, FreeMode } from 'swiper/modules'
import Image from 'components/Image'
import { getLoadedImagesArray, getNumberArray } from 'data/helpers/carousel'
import { useOnScreen } from 'data/hooks/useOnScreen'
import Paragraphs from 'components/home/common/typography/Paragraphs'
import Headers from 'components/home/common/typography/Headers'

const CarouselFeaturedProperties = ({ featuredProperties = [] }) => {
  const componentRef = React.useRef()

  const pagination = {
    type: 'bullets',
    clickable: true,
    clickableClass: 'carousel-clickable-featured-properties',
    bulletClass: 'carousel-bullet ',
  }

  const breakpoints = {
    768: {
      spaceBetween: 30,
    },
  }

  const imagesInfront = 3
  const imagesBehind = 2
  const imagesLength = featuredProperties.length
  const numberArray = getNumberArray(imagesLength)
  const [loadedImages, setLoadedImages] = useState([...numberArray.slice(0, imagesInfront), ...numberArray.slice(-imagesBehind)])

  const { initialLoad } = useOnScreen(componentRef)

  return (
    <div ref={componentRef}>
      {
        (initialLoad && (featuredProperties ?? []).length > 0)
        && <Swiper
          modules={[Pagination, FreeMode]}
          spaceBetween={20}
          slidesPerView={'auto'}
          observeParents={true}
          observer={true}
          pagination={pagination}
          centeredSlides={true}
          breakpoints={breakpoints}
          loop={true}
          freeMode={{
            enabled: true,
            sticky: true,
            momentum: false,
          }}
          onRealIndexChange={(swiper) => {
            if (loadedImages.length !== imagesLength) {
              setLoadedImages(getLoadedImagesArray(
                swiper.realIndex,
                imagesInfront,
                imagesLength,
                numberArray,
                loadedImages,
              ))
            }
          }}
        >
          <>
            {
              featuredProperties.map((property, index) => (
                <SwiperSlide
                  className="!w-[265px] sm:!w-[395px] mt-[62px]"
                  key={index}
                >
                  <Link
                    className="w-[265px] sm:w-[395px]"
                    href={`/${property.url}`}
                  >
                    <div className="h-[435px] sm:h-[650px] relative">
                      {loadedImages.includes(index)
                        && <Image
                          src={property.main_image?.url ?? placeholderImage}
                          alt={property.main_image_alt_text ?? ''}
                          className="absolute inset-0 object-cover rounded-4"
                          layout="fill"
                          priority
                        />
                      }
                    </div>
                    <Paragraphs.XL className="z-40 font-normal opacity-90 text-primary mt-15 ">
                      {
                        [property.region?.value, property.country?.value]
                          .filter(v => v)
                          .join(', ')
                      }
                    </Paragraphs.XL>

                    <Headers.XL className="mt-10 font-serif antialiased font-normal tracking-default text-grey-800">
                      {property.title}
                    </Headers.XL>

                  </Link>
                </SwiperSlide>
              ))}
          </>
        </Swiper>
      }
    </div>

  )
}

CarouselFeaturedProperties.propTypes = {
  featuredProperties: PropTypes.array,
}

export default CarouselFeaturedProperties
